import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layout */
import Layout from '@/layout'
import UploadListingsCost from "@/components/UploadListingsCost";
import Login from "@/views/login/index.vue";
import SPAuthorized from "@/components/SPAuthorized";
import ADAuthorized from "@/components/ADAuthorized";
import SPLogin from "@/components/SPLogin";
import AddChannel from "@/components/AddChannel";

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: Login },
  {
    path: '/',
    component: Layout,
    redirect: '/master-item-directory',
    children: [
      {
        path: 'master-item-directory',
        component: () => import('@/views/master-item-directory/index.vue'),
        name: 'MasterItemDirectory',
        meta: { title: 'Master Item Directory' }
      },
      {
        path: 'return-on-investment',
        component: () => import('@/views/return-on-investment/index.vue'),
        name: 'ReturnOnInvestment',
        meta: { title: 'Return on Investment (ROI)' }
      }
    ]
  },
  { path: '/upload-listings-cost', component: UploadListingsCost },
  { path: '/sp-login', component: SPLogin },
  { path: '/sp-authorized', component: SPAuthorized },
  { path: '/callback', component: ADAuthorized },
  { path: '/add-channel', component: AddChannel },
];


const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes,
  hash: false,
})

export default router
