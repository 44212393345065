<template>
  <header class="navbar navbar-expand-md navbar-light">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-menu"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3 mr-4">
        <a href="#">Legendcost</a>
      </h1>
      <div class="navbar-nav flex-row order-md-last">
        <div class="nav-item dropdown">
          <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-toggle="dropdown">
            <span class="avatar" style="background-image: url(./static/avatars/000m.jpg)"></span>
            <div class="d-none d-xl-block pl-2">
              <div v-if="user">{{ user }}</div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" href="#" @click.prevent="logout()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon dropdown-item-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path
                  d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
                />
                <path d="M7 12h14l-3 -3m0 6l3 -3" />
              </svg>
              Logout
            </a>
          </div>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="navbar-menu">
        <div
          class="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center"
        >
          <ul class="navbar-nav">
            <li
              class="nav-item"
              :class="{'active-link active':($route.name == 'MasterItemDirectory') }"
            >
              <router-link class="nav-link" :to="{name: 'MasterItemDirectory'}">
                <span class="nav-link-title">Master Item Directory</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{'active-link active':($route.name == 'ReturnOnInvestment') }"
            >
              <router-link class="nav-link" :to="{name: 'ReturnOnInvestment'}">
                <span class="nav-link-title">Return on Investment (ROI)</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { removeToken, removeUser, getUser } from "@/utils/auth";
export default {
  computed: {
    user() {
      return getUser();
    }
  },
  methods: {
    logout() {
      removeToken();
      removeUser();
      window.location = "/login";
    }
  }
};
</script>
<style scoped>
.nav-link {
  border-radius: 4px;
}
</style>