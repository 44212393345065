<template>
  <div class="wrapper">
    <navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
export default {
  name: "Layout",
  components: {
    Navbar
  }
};
</script>