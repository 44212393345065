import Vue from 'vue'
import Antd from 'ant-design-vue';
//import Router from 'vue-router'
import App from './App.vue'
import router from './router'
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false
//Vue.use(Router)
Vue.use(Antd);

//CSS FILES
import './assets/tabler/libs/jqvmap/dist/jqvmap.min.css';
import './assets/tabler/css/tabler.min.css';
import './assets/tabler/css/demo.min.css';

//JS FILES
import './assets/tabler/js/jquery-3.4.1.slim.min.js';
import './assets/tabler/libs/bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/tabler/libs/apexcharts/dist/apexcharts.min.js';
import './assets/tabler/js/tabler.min.js';
import interceptorsSetup from '@/utils/interceptors'
import '@/styles/style.css' // global css
interceptorsSetup()

Vue.component('pagination', require('laravel-vue-pagination'));
import Element from 'element-ui'
import enLang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
locale.use(enLang);
Vue.use(Element)

import 'element-ui/lib/theme-chalk/index.css';
new Vue({
    render: h => h(App),
    router
}).$mount('#app')
