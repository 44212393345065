import axios from 'axios';
import { getToken } from '@/utils/auth'

export default function setup() {
    axios.interceptors.request.use(function (config) {
        config.headers.Authorization = getToken() ? `Bearer ${getToken()}` : '';
        return config;
    }, function (err) {
        return Promise.reject(err);
    });
    axios.interceptors.response.use(
        (response) => {
            // all 2xx/3xx responses will end here

            return response;
        },
        (error) => {
            // all 4xx/5xx responses will end here
            if (error.response.status === 401) {
                window.location.href = '/login'
            }
            return Promise.reject(error);
        }
    );
}