<template>
  <div>
    SP Login
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SPAuthorized",
  data(){
    return {

    };
  },
  async mounted() {
    let response = await axios.get(`${process.env.VUE_APP_API_URL}/amazon/sp/login`, {params: this.$route.query});
    window.location.href = response.data.url;
  }
}
</script>

<style scoped>

</style>
