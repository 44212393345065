<template>
  <div>
    <div>
      <a href="/add-channel" class="route-link">Add New Channel</a>
      <!--      <router-link to="/sp-authorized" class="route-link">Authorize your seller partner</router-link>-->
      <!--      <router-link to="/ad-authorized">Authorize your advertising</router-link>-->
    </div>

    <p>Upload Listings Cost(.csv)</p>
    <input type="file" @change="uploadFile" ref="file">
    <button @click="submitFile">Upload!</button>
    <p v-if="responseMessage">{{ responseMessage }}</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadListingsCost",
  data() {
    return {
      file: null, responseMessage: null,
      sp_url: null,
      ad_url: null,
    };
  },
  methods: {
    uploadFile() {
      this.file = this.$refs.file.files[0];
    },
    submitFile() {
      const formData = new FormData();
      formData.append('file', this.file);
      axios.post(`${process.env.VUE_APP_API_URL}/listings-cost`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then((res) => {
            console.log(res);
            this.responseMessage = 'File Uploaded successfully'
          })
          .catch(e => {
            this.responseMessage = e.message;
          });
    }
  }
}
</script>

<style scoped>

</style>
