<template>
  <div class="page page-center">
    <div class="container-tight py-4">
      <form class="card card-md">
        <div class="card-body">
          <h2 class="card-title text-center mb-4">Login to your account</h2>
          <h5 v-if="error_message" class="content-group">
            <el-alert :title="error_message" type="error" show-icon></el-alert>
          </h5>
          <el-form
            ref="loginForm"
            :model="loginForm"
            label-position="top"
            size="small"
            :rules="rules"
          >
            <el-form-item label="Username" prop="username">
              <el-input size="medium" v-model="loginForm.username" placeholder="Enter username .."></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input
                size="medium"
                v-model="loginForm.password"
                placeholder="Enter your password.."
                show-password
              ></el-input>
            </el-form-item>

            <el-button
              class="btn-block"
              type="primary"
              native-type="submit"
              size="medium"
              :loading="loading"
              @click="login"
            >Sign in</el-button>
          </el-form>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { login } from "@/services/auth.js";
import { setToken, setUser } from "@/utils/auth";
export default {
  name: "login",
  data() {
    return {
      form: this.$form.createForm(this, { name: "coordinated" }),
      loginForm: {},
      loading: false,
      rules: {
        username: [
          {
            required: true,
            message: "Enter username",
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "Enter your password", trigger: "blur" }
        ]
      },
      error_message: null
    };
  },

  methods: {
    async login(e) {
      this.error_message = null;
      e.preventDefault();
      this.$refs["loginForm"].validate(async valid => {
        if (valid) {
          this.loading = true;
          let loginStatus = await login(this.loginForm);
          if (loginStatus) {
            this.loading = false;
            setToken(loginStatus.data.access_token);
            setUser(loginStatus.data.user);
            this.$router.push({ path: "/" });
          } else {
            this.error_message = "The given data was invalid.";
          }
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loginForm = values;
          this.login();
        }
      });
    }
  }
};
</script>
<style scoped>
.page-center {
  justify-content: center;
}
</style>