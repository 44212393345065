<template>
  <div id="app">
    <!--    <img alt="Vue logo" src="./assets/amazon_logo.png" width="300">-->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;

  font-size: 0.875rem;
}
body {
  font-size: 16px;
}
.route-link {
  margin-right: 10px;
} */
</style>
