<template>
  <div>
    <div v-if="authorized === null">
      Adding...
    </div>

    <div class="success-msg" v-if="authorized">
      Your advertising account authorized successfully.
    </div>

    <div class="error-msg" v-if="authorized === false">
      We can't authorize your advertising account, please try again.
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ADAuthorized",
  data() {
    return {
      authorized: null
    };
  },
  async mounted() {
    let response = await axios.get(`${process.env.VUE_APP_API_URL}/amazon/ad/redirect`, {params: this.$route.query});
    this.authorized = response.data.success;
  }
}
</script>

<style scoped>
.info-msg,
.success-msg,
.warning-msg,
.error-msg {
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
}

.info-msg {
  color: #059;
  background-color: #BEF;
}

.success-msg {
  color: #270;
  background-color: #DFF2BF;
}

.warning-msg {
  color: #9F6000;
  background-color: #FEEFB3;
}

.error-msg {
  color: #D8000C;
  background-color: #FFBABA;
}

</style>
