<template>
  <div class="page-wrapper">
    <!-- Page title -->
    <div class="container-fluid">
      <div class="page-steps">
        <a-steps :current="current">
          <a-step class="step" v-for="item in steps" :key="item.title" :title="item.title" />
        </a-steps>
        <div class="steps-content">
          <div v-show="current === 0">
            <a-form
              :form="form"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 12 }"
              @submit="handleSubmit"
            >
              <a-form-item label="Channel Name">
                <a-input
                  v-decorator="['name', { rules: [{ required: true, message: 'Please input channel name!' }] }]"
                />
              </a-form-item>
              <a-form-item label="Seller Id">
                <a-input
                  v-decorator="['seller_id', { rules: [{ required: true, message: 'Please input seller id!' }] }]"
                />
              </a-form-item>
            </a-form>
          </div>
          <div v-if="current === 1">
            <a :href="sp_url" target="_blank">Authorize your seller partner</a>
          </div>
          <div v-if="current === 2">
            <a :href="ad_url" target="_blank">Authorize your advertising</a>
          </div>
        </div>
        <div class="steps-action">
          <div class="row">
            <div class="col-auto ml-auto">
              <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
              <a-button v-if="current === steps.length - 1" type="primary" @click="addChannel">Done</a-button>
              <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AddChannel",
  data() {
    return {
      current: 0,
      steps: [
        {
          title: "Channel Info",
          content: "First-content"
        },
        {
          title: "Authorize Seller Partner",
          content: "Second-content"
        },
        {
          title: "Authorize Advertising",
          content: "Last-content"
        }
      ],
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      sp_url: null,
      ad_url: null,
      channel: {}
    };
  },
  async mounted() {
    let spResponse = await axios.get(
      `${process.env.VUE_APP_API_URL}/amazon/sp/auth-url`
    );
    this.sp_url = spResponse.data.url;
  },
  methods: {
    async next() {
      if (this.current === 0) {
        this.handleSubmit();
      } else if (this.current === 1) {
        await this.spAuthorized();
      } else {
        this.current++;
      }
    },
    prev() {
      this.current--;
    },
    handleSubmit() {
      // e.preventDefault();
      // console.log(this.form.getFieldsValue());
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          this.channel = values;
          this.current++;
        }
      });
    },
    async spAuthorized() {
      let spResponse = await axios.get(
        `${process.env.VUE_APP_API_URL}/amazon/sp/sp-authorized?seller_id=${this.channel.seller_id}`
      );
      if (spResponse.data.success) {
        let adResponse = await axios.get(
          `${process.env.VUE_APP_API_URL}/amazon/ad/auth-url?seller_id=${this.channel.seller_id}`
        );
        this.ad_url = adResponse.data.url;

        this.current++;
      } else {
        this.$message.error("Seller Partner Not Authorized");
      }
    },
    async addChannel() {
      let addChannelResponse = await axios.post(
        `${process.env.VUE_APP_API_URL}/channels`,
        this.channel
      );
      if (addChannelResponse.data.success) {
        this.$message.success("Processing complete!", 0);
      } else {
        this.$message.error("Advertising Not Authorized");
      }
    }
  }
};
</script>
<style scoped>
.page-wrapper {
  background: #fff;
  height: 100vh;
  padding-top: 100px;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 40px;
}

.steps-action {
  margin-top: 24px;
}

.page-steps {
  width: 80%;
  margin-left: 10%;
}

.step {
  text-align: left;
}
</style>
